/* eslint-disable no-useless-escape */
interface ProfileField {
  label: string;
  name: string;
  pattern: RegExp;
  required: boolean;
  type: 'text' | 'password' | 'email' | 'number' | 'phone';
  validationLabel: string;
}

export const ACCOUNT_INFO_FIELDS: ProfileField[] = [
  {
    label: 'First Name',
    name: 'firstName',
    pattern: /^[A-Za-z.\s_-]+$/,
    required: false,
    type: 'text',
    validationLabel: 'firstName is required and accept only characters'
  },
  {
    label: 'Last Name',
    name: 'lastName',
    pattern: /^[A-Za-z.\s_-]+$/,
    required: false,
    type: 'text',
    validationLabel: 'lastName is required and accept only characters'
  },
  {
    label: 'Email address',
    name: 'email',
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    required: false,
    type: 'email',
    validationLabel: 'email should have the format example@mail.com'
  },
  {
    label: 'Phone number',
    name: 'phone',
    pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    required: false,
    type: 'phone',
    validationLabel: 'phone should have the format +(1) 323-133-2222'
  }
];

export const ACCOUNT_B2B_FIELDS: ProfileField[] = [
  {
    label: 'Company Name',
    name: 'businessName',
    pattern: /[^\n]+/,
    required: false,
    type: 'text',
    validationLabel: '(*) is required'
  },
  {
    label: 'Job title',
    name: 'jobTitle',
    pattern: /[^\n]+/,
    required: false,
    type: 'text',
    validationLabel: '(*) is required'
  }
];

export const IMPORT_FIELDS: ProfileField[] = [
  {
    label: 'First Name',
    name: 'firstName',
    pattern: /^[A-Za-z.\s_-]+$/,
    required: true,
    type: 'text',
    validationLabel: 'firstName is required and accept only characters'
  },
  {
    label: 'Last Name',
    name: 'lastName',
    pattern: /^[A-Za-z.\s_-]+$/,
    required: false,
    type: 'text',
    validationLabel: 'lastName is required and accept only characters'
  },
  {
    label: 'Email address',
    name: 'email',
    pattern:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    required: true,
    type: 'email',
    validationLabel: 'email should have the format example@mail.com'
  },
  {
    label: 'Phone number',
    name: 'phone',
    pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    required: false,
    type: 'phone',
    validationLabel: 'phone should have the format +(1) 323-133-2222'
  },
  {
    label: 'Company Name',
    name: 'businessName',
    pattern: /[^\n]+/,
    required: false,
    type: 'text',
    validationLabel: '(*) is required'
  },
  {
    label: 'Job title',
    name: 'jobTitle',
    pattern: /[^\n]+/,
    required: false,
    type: 'text',
    validationLabel: '(*) is required'
  }
];

export const BILLING_INFO_FIELDS: [] = [];
