/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ComponentProps } from 'react';
import '../../style/App.scss';
import {
  styled,
  Autocomplete,
  Typography,
  createFilterOptions
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AutoProps extends ComponentProps<typeof Autocomplete> {
  closeOnSelect?: boolean;
  // eslint-disable-next-line react/require-default-props
  defaultValue?: any;
  disableCreate?: boolean;
  // eslint-disable-next-line react/require-default-props
  multiple?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: any, newValue: any | null) => void;
  options: { id: string; label?: string, name: string }[];
  value: any;
}

export const AutoCompleteStyled = styled(Autocomplete)(() => ({
  '& .MuiOutlinedInput-root': {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0
  },
  '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
    height: 40,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingTop: 8
  },
  '& li': {
    fontSize: '12px'
  }
}));

const filter = createFilterOptions<any>();

const AutoCompleteInput: FC<AutoProps> = function AutoCompleteInput({
  ...props
}) {
  const { t } = useTranslation();
  const { multiple, value, sx } = props;
    
  return (
    <AutoCompleteStyled
      disableClearable
      fullWidth
      defaultValue={props.defaultValue}
      disableCloseOnSelect={!props.closeOnSelect}
      disabled={props.disabled}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
          filtered.push({
            createValue: params.inputValue,
            id: params.inputValue,
            name: `${t('MODAL.create')} "${params.inputValue}"`
          });
        }
        return filtered;
      }}
      getOptionLabel={(option: any) =>
        option?.createValue || option?.label || option?.name || ''
      }
      multiple={multiple}
      noOptionsText={null}
      onChange={props.onChange}
      options={props.options}
      renderInput={props.renderInput}
      renderOption={(props, option: any) => {
        if (Array.isArray(value)) {
          const ids = value.map((i) => i.id);
          if (ids.includes(option.id)) {
            return null;
          }
        } else if (value && option && value.id === option.id) {
          return null;
        }
        return (
          <li {...props}>
            <Typography
              color="mainText.500"
              sx={{ fontSize: '15px' }}
              variant="primary-bold"
            >
              {(option.label || option.name)}
            </Typography>
          </li>
        );
      }}
      sx={{ ...sx }}
      value={value}
    />
  );
};

AutoCompleteInput.defaultProps = {
  closeOnSelect: false,
  disableCreate: false,
  multiple: false
};

export default AutoCompleteInput;
